


























































































.icons-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 24px;
}

.icon-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .icon-background {
    min-height: 100px;
    background-color: #68d391;
    margin-bottom: 8px;
    padding: 16px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  a {
    text-align: center;
  }
}
